import React, { Component, ReactElement } from "react";
import styled from "styled-components";
import { Button, ButtonGroup, Form, Col, Row } from "react-bootstrap";

import { SocketEvent } from "../types/socketEvent";

const Title = styled.div`
  font-family: "Baloo Tamma 2", cursive;
  font-size: 5em;
  color: whitesmoke;
  padding-top: 0.5em;
`;

const Container = styled.div`
  display: inline-block;
  width: 27em;
  padding: 2em;
  @media only screen and (max-width: 27em) {
    width: 90%;
  }
`;

const GitHubLogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.1em;
  transform: scale(0.5);
  &:hover {
    filter: invert(100%);
  }
`;

enum FormToggled {
  JOIN = "JOIN",
  CREATE = "CREATE",
}

type LandingPageProps = {
  socket: SocketIOClient.Socket;
};

type LandingPageState = {
  formToggled: FormToggled;
  gameCodeIsValid: boolean | null;
};

class LandingPage extends Component<LandingPageProps, LandingPageState> {
  constructor(props: LandingPageProps) {
    super(props);
    this.onToggleJoin = this.onToggleJoin.bind(this);
    this.onToggleCreate = this.onToggleCreate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { formToggled: FormToggled.CREATE, gameCodeIsValid: null };
  }

  onToggleJoin(): void {
    this.setState({ formToggled: FormToggled.JOIN });
  }

  onToggleCreate(): void {
    this.setState({ formToggled: FormToggled.CREATE });
  }

  handleSubmit(): void {
    const { socket } = this.props;
    const { formToggled } = this.state;
    const playerNameNode = document.getElementById(
      "playerName"
    ) as HTMLInputElement;

    if (typeof playerNameNode === "undefined") {
      alert("Error submitting form. Try refreshing the page.");
      return;
    }

    const playerName = playerNameNode.value;

    socket.on(SocketEvent.SERVER_INVALID_GAME_CODE, () => {
      this.setState({ gameCodeIsValid: false });
    });

    if (formToggled === FormToggled.JOIN) {
      const gameCodeNode = document.getElementById(
        "gameCode"
      ) as HTMLInputElement;

      if (typeof gameCodeNode === "undefined") {
        alert("Error submitting form. Try refreshing the page.");
        return;
      }

      const gameCode = gameCodeNode.value;
      socket.emit(SocketEvent.CLIENT_JOIN_GAME, {
        playerName,
        gameCode,
      });
    } else {
      console.log("create game");
      const gameModeNode = document.getElementById(
        "gameMode"
      ) as HTMLInputElement;

      let gameMode = "NORMAL"
      if (typeof gameModeNode != null) {
        gameMode = gameModeNode.value;
      }

      socket.emit(SocketEvent.CLIENT_CREATE_GAME, {
        playerName, gameMode,
      });
    }
  }

  handleGenerateName(): void {
    const adjectives = ["Naughty", "Typing", "Ignorant", "Brilliant", "Code", "Salty", "Hot", "Loud", "Opinionated", "Sweaty", "Lazy", "Happy", "Hungry", "Sassy", "Broke", "Pale", "Orange", "Badass", "Smart", "Cutthroat", "Old", "Sparkling", "Hangry", "Mythical", "👎🍑", "Sexy", "WetAss", "Certified", "Wholesome", "Christmassy", "Gorgeous", "Jazzy", "Witty", "Tall", "Freaky", "Stubborn", "Silly", "Beautiful", "Steamy", "Dreamy", "Loving", "Caring", "Cozy", "Perfect", "Affectionate", "rEmArKaBlE", "Cute"];
    const nouns = ["Zaddy", "Mountain", "Monkey", "Stetson", "Alisa", "Giant", "Glasses", "Curry", "Stonks", "Steamroller", "Boi", "Meme", "Baddie", "Thought", "Smoothie", "Heart", "Recipe", "Tears", "Workout", "CardiB", "Boba", "⛰️", "Ears", "Fries", "Freak", "Brain", "Flower", "Texas", "Florida", "Cali", "Australia", "Seattle", "Cowgirl", "Jet", "Cutie", "Hoe", "Darling", "Boo", "Sunshine", "Pie", "Hug", "Kiss", "Cupcake", "Sweetie", "Cuddle", "Date", "Smile", "Eyes", "Lips", "Nose", "Chest", "Arms", "Love", "Boyfriend", "Girlfriend", "🔥Hottie🔥", "Tennessee", "Queen", "King"];
    let username = "";
    username += adjectives[Math.floor(Math.random() * adjectives.length)];
    username += nouns[Math.floor(Math.random() * nouns.length)];
    const playerNameNode = document.getElementById(
      "playerName"
    ) as HTMLInputElement;

    if (typeof playerNameNode === "undefined") {
      return;
    }
    playerNameNode.value = username;
  }

  render(): ReactElement {
    const titleText = (
      <span>
        Uno
      </span>
    );

    const { formToggled, gameCodeIsValid } = this.state;

    return (
      <>
        <Title>{titleText}</Title>
        <Container>
          <ButtonGroup
            aria-label="Basic example"
            style={{ paddingBottom: "1em", width: "100%" }}
          >
            <Button
              variant="outline-light"
              style={{ boxShadow: "none" }}
              className={
                formToggled === FormToggled.JOIN ? "active" : undefined
              }
              onClick={this.onToggleJoin}
            >
              Join Game
            </Button>
            <Button
              variant="outline-light"
              style={{ boxShadow: "none" }}
              className={
                formToggled === FormToggled.CREATE ? "active" : undefined
              }
              onClick={this.onToggleCreate}
            >
              Create Game
            </Button>
          </ButtonGroup>
          <Form>
            {formToggled === FormToggled.JOIN && (
              <Form.Group>
                <Form.Control
                  id="gameCode"
                  size="lg"
                  type="text"
                  placeholder="Enter game code"
                  style={{ textAlign: "center" }}
                  // isValid={typeof gameCodeIsValid !== null && gameCodeIsValid}
                  isInvalid={gameCodeIsValid !== null && !gameCodeIsValid}
                />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Control
                id="playerName"
                size="lg"
                type="text"
                placeholder="Enter player name"
                style={{ textAlign: "center", display: "inline-block", width: "78%" }}
                // isValid={typeof nameIsValid !== 'undefined' && nameIsValid}
                // isInvalid={typeof nameIsValid !== 'undefined' && !nameIsValid}
              />
              <Button
                variant="link"
                style={{ width: "19%", color: "white", fontFamily: "Lucida Sans Unicode" }}
                onClick={this.handleGenerateName}
                >
                  ⟳
                </Button>
            </Form.Group>
            {formToggled === FormToggled.CREATE && (
              <Form.Group as={Row} controlId="formGameMode">
                <Form.Label style={{color: 'white', margin: 'auto', fontSize: '20px'}} column sm={4}>Game Mode:</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    id="gameMode"
                    size="lg"
                    as="select"
                    style={{ textAlign: 'center' }}>
                      <option value="NORMAL">Normal</option>
                      <option value="FLIP">Flip</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            )}
            <Button
              variant="primary"
              style={{ width: "100%" }}
              onClick={this.handleSubmit}
            >
              {formToggled === FormToggled.JOIN ? "Join Game" : "Create Game"}
            </Button>
          </Form>
        </Container>
      </>
    );
  }
}

export default LandingPage;
