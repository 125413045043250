
import React, { Component, ReactElement, RefObject } from "react";
import styled from "styled-components";
import { Button, ButtonGroup, Form, Col, Row } from "react-bootstrap";
import { SocketEvent } from "../types/socketEvent";

const ChatWindowContainer = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 50px;
  right: 15px;
  height: 30%;
  width: 20%;
  color: whitesmoke;
  font-size: 1.4em;
  background: rgb(202 202 202 / 20%);
  border-radius: 0.2em;
  border-color: white;
  border-width: thin;
  padding: 0.1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
  text-align: left;
`;

const ChatInputContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 98%;
  padding-left: 2%;
  padding-right: 2%;
`

const ChatMessageContainer = styled.div`
  overflow-y: scroll;
  height: calc(100% - (1.5em + 1rem + 10px));
  overflow-wrap: break-word;
`

const ChatMessageView = styled.div`
  padding: 5px;
`

export type ChatMessage = {
    username: String;
    message: String;
  }

type ChatWindowProps = {
    handleSendMessage: (message: String) => void;
    messages: ChatMessage[];
  };

class ChatWindow extends Component<ChatWindowProps> {
    private messagesEnd : React.RefObject<HTMLDivElement> = React.createRef();

    scrollToBottom() : void {
        // this.messagesEnd.current?.scrollIntoView({ behavior: 'smooth' })
        this.messagesEnd.current?.scrollIntoView()
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    preventDefaultSubmission(event: React.FormEvent) : void {
        event.preventDefault();
    }

    handleSubmit(): void {
        const messageNode = document.getElementById(
            "chatMessageInput"
          ) as HTMLInputElement;
          if (typeof messageNode === "undefined") {
            alert("Error submitting message.");
            return;
          }
          const message = messageNode.value;
          console.log(message);
          this.props.handleSendMessage(message);
          messageNode.value = "";
    }

    render(): ReactElement {
        var messageList = this.props.messages.map(function(message){
            return <ChatMessageView><b>{message.username}: </b>{message.message}</ChatMessageView>;
        })
        return <ChatWindowContainer>
            <ChatMessageContainer>
                {messageList}
                <div ref={this.messagesEnd}></div>
            </ChatMessageContainer>
            <ChatInputContainer>
                <Form onSubmit={this.preventDefaultSubmission}>
                    <Form.Group>
                        <Form.Control
                        id="chatMessageInput"
                        size="lg"
                        type="text"
                        placeholder="Enter message"
                        onKeyPress={(event: { key: string; }) => {
                            if (event.key === "Enter") {
                              this.handleSubmit();
                            }
                          }}
                        />
                    </Form.Group>
                </Form>
            </ChatInputContainer>
        </ChatWindowContainer>
    }
}

export default ChatWindow;