import React, { ReactElement } from "react";
import styled from "styled-components";
import { CardClickHandler, DummyClickHandler } from "../types/cardClickHandler";
import { Color } from "../types/color";
import { JSONCard } from "../types/jsonCard";
import { Rotation } from "../types/rotation";
import Card from "./card/card";

const Center = styled.div`
  position: absolute;
  height: 10em;
  width: 10em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

type BoardCenterProps = {
  topCard: JSONCard | null;
  drawClickHandler: CardClickHandler;
  passClickHandler: CardClickHandler;
  showPass: boolean;
};

const createCardFromJson = (cardJson : JSONCard | null) => {
  if (cardJson == null) {
    return null;
  }
  return (
    <Card
      color={cardJson.color}
      value={cardJson.value}
      cardId={cardJson.cardId}
      wildColor={cardJson.wildColor}
      rotation={Rotation.BOTTOM}
      handleCardClick={DummyClickHandler}
      invisible={false}
      playable={false}
    />
  )
}

const createDrawPile = (drawClickHandler : CardClickHandler, passClickHandler : CardClickHandler, showPass : boolean) => {
  return (
    <Card
      color={Color.DRAW}
      value={showPass ? "Pass" : "Draw"}
      cardId={0}
      wildColor={null}
      rotation={Rotation.LEFT}
      handleCardClick={showPass ? passClickHandler : drawClickHandler}
      invisible={false}
      playable={false}
    />
  )
}

function BoardCenter(props: BoardCenterProps): ReactElement {
  const { topCard, drawClickHandler, passClickHandler, showPass } = props;

  return (
    <Center>
      {createCardFromJson(topCard)}
      {createDrawPile(drawClickHandler, passClickHandler, showPass)}
      {/* {cards[0]}
      {cards[1]}
      {cards[2]}
      {cards[3]} */}
    </Center>
  );
}

export default BoardCenter;
