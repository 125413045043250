import React, { Component, Fragment, ReactElement } from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";

import { CardClickHandler } from "../../types/cardClickHandler";
import { Color } from "../../types/color";
import { Rotation } from "../../types/rotation";
import { ReactComponent as ReverseIcon } from "../../assets/unoCardReverseIcon.svg";

import "./unoCard.styles.css";
import "./miniCard.styles.css";
import "./unoCardWildCircle.styles.css";

// Firefox 1.0+
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const InstallTrigger: any;
const isFirefox = typeof InstallTrigger !== "undefined";

const MiniCard = ({ color, margin, zIndex, additionalStyles }: any) => {
  return (
    <div
      style={{
        background: color,
        margin,
        zIndex,
        ...additionalStyles,
      }}
      className="uno-card-mini-card"
    ></div>
  );
};

const UnoCardWildCircle = ({ additionalStyles }: any) => {
  return (
    <div
      style={{ ...additionalStyles }}
      className="uno-card-wild-middle-circle"
    >
      <Row className="uno-card-wild-color-row">
        <div
          style={{ background: "rgb(254, 39, 39)" }}
          className="sm-3 uno-card-wild-color"
        ></div>
        <div
          style={{ background: "rgb(9, 158, 255)" }}
          className="sm-3 uno-card-wild-color"
        ></div>
      </Row>
      <Row className="uno-card-wild-color-row">
        <div
          style={{ background: "#f0ce07" }}
          className="sm-3 uno-card-wild-color"
        ></div>
        <div
          style={{ background: "#08ba22" }}
          className="sm-3 uno-card-wild-color"
        ></div>
      </Row>
    </div>
  );
};

const CardBody = styled.div`
  position: ${(props: {
    rotation: Rotation | undefined;
    selected: boolean;
    invisible: boolean;
    backgroundColor: Color;
    wildColor: Color | null;
    playable: boolean;
  }) => {
    if (props.rotation) {
      return "absolute";
    }
    return "relative";
  }};
  height: 4.5em;
  width: 3.5em;
  background: ${(props) => {
    if (props.backgroundColor) {
      if (props.backgroundColor === Color.WILD) {
        if (props.wildColor == null) {
          return "black"
        }
        return props.wildColor
      }
      return props.backgroundColor
    }
    return "white"
  }};
  color: black;
  font-family: "DM Serif Display", serif;
  ${(props) => props.invisible && `opacity: 0;`}
  ${(props) => props.playable && `box-shadow: 0 0 8px #fdff92;`}
  ${(props) =>
    !props.rotation &&
    `
    margin: .1em;`};
  ${(props) =>
    props.rotation &&
    `
    left: 50%;
    top 25%;
  `};
  transform: ${(props) => {
    if (props.rotation === Rotation.TOP) {
      return "translate(-50%, 0%) rotate(0) translate(0, -125%)";
    }
    if (props.rotation === Rotation.LEFT) {
      return "translate(-140%, 0%)";
    }
    if (props.rotation === Rotation.RIGHT) {
      return "translate(-50%, 0%) rotate(90deg) translate(0, -125%)";
    }
    // BOTTOM
    return "none";
  }};
  transform-origin: top center;
  border-radius: 0.2em;
  border-style: solid;
  border-color: grey;
  border-width: thin;
  // ${(props) =>
    props.selected &&
    `
  //   -moz-opacity: 0.5; /* Firefox and Mozilla browsers */
  //   -webkit-opacity: 0.5; /* WebKit browser e.g. Safari */
  //   filter: alpha(opacity=50); /* For IE8 and earlier */`};
  cursor: pointer;
`;

type CardProps = {
  color: Color;
  value: string;
  cardId: number;
  wildColor: Color | null;
  rotation: Rotation | undefined;
  handleCardClick: CardClickHandler;
  invisible: boolean;
  playable: boolean;
};

type CardState = {
  selected: boolean;
};

class Card extends Component<CardProps, CardState> {
  constructor(props: CardProps) {
    super(props);
    this.state = { selected: false };
  }

  getKey(): string {
    const { value, color, cardId } = this.props;
    return value + color + cardId;
  }

  renderUnoCard(value: string, color: Color, cardId: number): ReactElement {
    if (color === Color.DRAW) {
      return (
        <Fragment>
          <div className="uno-card-middle">
            <span
              style={{ color: "black" }}
              className="uno-card-middle-circle-word"
            >
              {value}
            </span>
            <div className="uno-card-middle-circle"></div>
          </div>
        </Fragment>
      )
    }
    const plusTwoCardStyles = {
      height: "32px",
      width: "17px",
    };
    if (color === Color.WILD) {
      if (value === "D4") {
        return (
          <Fragment>
            <span className="uno-card-small-number">+4</span>
            <div className="uno-card-middle">
              <MiniCard
                color="#08ba22"
                margin="-6px 0px 0px 14px"
                zIndex="12"
              />
              <MiniCard
                color="#099eff"
                margin="-29px 0px 0px 6px"
                zIndex="13"
              />
              <MiniCard
                color="#f0ce07"
                margin="18px 0px 0px -15px"
                zIndex="11"
              />
              <MiniCard
                color="#fe2727"
                margin="-3px 0px 0px -24px"
                zIndex="12"
              />
              <div className="uno-card-middle-circle"></div>
            </div>
            <span className="uno-card-small-number flipped">+4</span>
          </Fragment>
        );
      } else if (value === "WD2") {
        return (
          <Fragment>
            <span className="uno-card-small-number">+2</span>
            <div className="uno-card-middle">
              <MiniCard
                color="#08ba22"
                margin="-6px 0px 0px 14px"
                zIndex="12"
              />
              <MiniCard
                color="#099eff"
                margin="-29px 0px 0px 6px"
                zIndex="13"
              />
              <MiniCard
                color="#f0ce07"
                margin="18px 0px 0px -15px"
                zIndex="11"
              />
              <MiniCard
                color="#fe2727"
                margin="-3px 0px 0px -24px"
                zIndex="12"
              />
              <div className="uno-card-middle-circle"></div>
            </div>
            <span className="uno-card-small-number flipped">+2</span>
          </Fragment>
        );
      } else if (value === "WDC") {
        return (
          <Fragment>
            <span className="uno-card-small-number">+C</span>
            <div className="uno-card-middle">
              <MiniCard
                color="#08ba22"
                margin="-6px 0px 0px 14px"
                zIndex="12"
              />
              <MiniCard
                color="#099eff"
                margin="-29px 0px 0px 6px"
                zIndex="13"
              />
              <MiniCard
                color="#f0ce07"
                margin="18px 0px 0px -15px"
                zIndex="11"
              />
              <MiniCard
                color="#fe2727"
                margin="-3px 0px 0px -24px"
                zIndex="12"
              />
              <div className="uno-card-middle-circle"></div>
            </div>
            <span className="uno-card-small-number flipped">+C</span>
          </Fragment>
        );
      } else {
        const miniCirlceStyles = {
          height: "20%",
          width: "20%",
          position: "absolute",
          border: "2px solid white",
        };
        return (
          <Fragment>
            <UnoCardWildCircle
              additionalStyles={{
                ...miniCirlceStyles,
                top: "10px",
                left: "10px",
              }}
            />
            <div className="uno-card-middle">
              <UnoCardWildCircle />
            </div>
            <UnoCardWildCircle
              additionalStyles={{
                ...miniCirlceStyles,
                bottom: "10px",
                right: "10px",
                transform: "rotate(180deg) skew(-15deg, -15deg)",
              }}
            />
          </Fragment>
        );
      }
    } else {
      switch (value) {
        case "S":
          return (
            <Fragment>
              <span className="uno-card-small-number">⊘</span>
              <div className="uno-card-middle">
                <span
                  style={{ color }}
                  className="uno-card-middle-circle-number"
                >
                  ⊘
                </span>
                <div className="uno-card-middle-circle"></div>
              </div>
              <span className="uno-card-small-number flipped">⊘</span>
            </Fragment>
          );
        case "SE":
          return (
            <Fragment>
              <span className="uno-card-small-number">↺</span>
              <div className="uno-card-middle">
                <span
                  style={{ color }}
                  className="uno-card-middle-circle-number"
                >
                  ↺
                </span>
                <div className="uno-card-middle-circle"></div>
              </div>
              <span className="uno-card-small-number flipped">↺</span>
            </Fragment>
          );
        case "R":
          return (
            <Fragment>
              <ReverseIcon
                style={{ top: "0px", left: "5px" }}
                className="reverse-icon-small"
              />
              <ReverseIcon className="uno-reverse-card-icon" />
              <div className="uno-card-middle">
                <div
                  style={{ background: color, border: "4px solid white" }}
                  className="uno-card-middle-circle"
                ></div>
              </div>
              <ReverseIcon
                style={{ bottom: "0px", right: "5px" }}
                className="reverse-icon-small"
              />
            </Fragment>
          );
        case "D2":
          return (
            <Fragment>
              <span className="uno-card-small-number">+2</span>
              <div className="uno-card-middle">
                <MiniCard
                  color={color}
                  margin="12px 0px 0px -18px"
                  zIndex="11"
                  additionalStyles={plusTwoCardStyles}
                />
                <MiniCard
                  color={color}
                  margin="-11px 0px 0px 12px"
                  zIndex="10"
                  additionalStyles={plusTwoCardStyles}
                />
                <div className="uno-card-middle-circle"></div>
              </div>
              <span className="uno-card-small-number flipped">+2</span>
            </Fragment>
          );
        case "D1":
          return (
            <Fragment>
              <span className="uno-card-small-number">+1</span>
              <div className="uno-card-middle">
                <MiniCard
                  color={color}
                  margin="12px 0px 0px -18px"
                  zIndex="11"
                  additionalStyles={plusTwoCardStyles}
                />
                <MiniCard
                  color={color}
                  margin="-11px 0px 0px 12px"
                  zIndex="10"
                  additionalStyles={plusTwoCardStyles}
                />
                <div className="uno-card-middle-circle"></div>
              </div>
              <span className="uno-card-small-number flipped">+1</span>
            </Fragment>
          );
          case "D5":
            return (
              <Fragment>
                <span className="uno-card-small-number">+5</span>
                <div className="uno-card-middle">
                  <MiniCard
                    color={color}
                    margin="12px 0px 0px -18px"
                    zIndex="11"
                    additionalStyles={plusTwoCardStyles}
                  />
                  <MiniCard
                    color={color}
                    margin="-11px 0px 0px 12px"
                    zIndex="10"
                    additionalStyles={plusTwoCardStyles}
                  />
                  <div className="uno-card-middle-circle"></div>
                </div>
                <span className="uno-card-small-number flipped">+5</span>
              </Fragment>
            );
        case "FLIP":
          return (
            <Fragment>
              <span className="uno-card-small-number">↶</span>
              <div className="uno-card-middle">
                <span
                  style={{ color }}
                  className="uno-card-middle-circle-number"
                >
                  ↶
                </span>
                <div className="uno-card-middle-circle"></div>
              </div>
              <span className="uno-card-small-number flipped">↶</span>
            </Fragment>
          );
        default:
          return (
            <Fragment>
              <span className="uno-card-small-number">{value}</span>
              <div className="uno-card-middle">
                <span
                  style={{ color }}
                  className="uno-card-middle-circle-number"
                >
                  {value}
                </span>
                <div className="uno-card-middle-circle"></div>
              </div>
              <span className="uno-card-small-number flipped">{value}</span>
            </Fragment>
          );
      }
    }
  }

  render(): ReactElement {
    const {
      value,
      color,
      cardId,
      wildColor,
      rotation,
      handleCardClick,
      invisible,
      playable,
    } = this.props;
    const { selected } = this.state;

    return (
      <CardBody
        backgroundColor={color}
        rotation={rotation}
        wildColor={wildColor}
        onClick={() => handleCardClick(color, value, cardId)}
        selected={selected}
        invisible={invisible}
        playable={playable}
      >
        {this.renderUnoCard(value, color, cardId)}
      </CardBody>
    );
  }
}

export default Card;
