import { Color } from "./color";

export type CardClickHandler = (
  color: Color,
  value: string,
  cardId: number
) => void;

export const DummyClickHandler: CardClickHandler = (
  color: Color,
  value: string,
  cardId: number
) => {
  return;
};
