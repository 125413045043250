import React, { ReactElement } from "react";
import styled from "styled-components";
import { DummyClickHandler } from "../types/cardClickHandler";
import { Color } from "../types/color";
import Card from "./card/card";

const HandContainer = styled.div`
  // display: table;
  // margin: 0 auto;
  padding-top: 2em;
  height: 5em;
`;

const CardContainer = styled.div`
  display: inline-block;
`;

// Used to prevent resizing of the Hands height
const DummyCard = (
  <Card
    color={Color.RED}
    cardId={0}
    value={"0"}
    wildColor={null}
    invisible={true}
    playable={false}
    rotation={undefined}
    handleCardClick={DummyClickHandler}
  />
);

function Hand(props: { cards: ReactElement<Card>[] }): ReactElement {
  const { cards } = props;
  let key = 0;
  return (
    <HandContainer>
      {cards.map((card: ReactElement<Card>) => (
        <CardContainer key={key++}>{card}</CardContainer>
      ))}
      {cards.length === 0 && DummyCard}
    </HandContainer>
  );
}

export default Hand;
