export enum SocketEvent {
  CLIENT_PLAY_CARD = "CLIENT_PLAY_CARD",
  CLIENT_JOIN_GAME = "CLIENT_JOIN_GAME",
  CLIENT_CREATE_GAME = "CLIENT_CREATE_GAME",
  CLIENT_LEAVE_GAME = "CLIENT_LEAVE_GAME",
  CLIENT_ADD_BOT = "CLIENT_ADD_BOT",
  CLIENT_SELECT_EMOJI = "CLIENT_SELECT_EMOJI",
  CLIENT_START_GAME = "CLIENT_START_GAME",
  CLIENT_DRAW_CARD = 'CLIENT_DRAW_CARD',
  CLIENT_PASS = 'CLIENT_PASS',
  CLIENT_CALL_UNO = 'CLIENT_CALL_UNO',
  CLIENT_LOG_ERROR = "CLIENT_LOG_ERROR",
  SERVER_INVALID_GAME_CODE = "SERVER_INVALID_GAME_CODE",
  SERVER_SENT_JOIN_INFO = "SERVER_SENT_JOIN_INFO",
  SERVER_UPDATED_GAME_STATE = "SERVER_UPDATED_GAME_STATE",
  SERVER_RELOAD_CONNECTION = "SERVER_RELOAD_CONNECTION",
  SERVER_ERROR = "SERVER_ERROR",
  SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE",
  RECEIVE_CHAT_MESSAGE = "RECEIVE_CHAT_MESSAGE",
}
