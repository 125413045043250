import React, { Component, ReactElement } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

import BoardCenter from "./boardCenter";
import Card from "./card/card";
import { Rotation } from "../types/rotation";
import Player from "./player";
import { PlayerInfo } from "../types/playerInfo";
import { JSONCard } from "../types/jsonCard";
import { CardClickHandler } from "../types/cardClickHandler";
import { Color } from "../types/color";

const BoardContainer = styled.div`
  position: relative;
  height: 300px;
  width: 700px;
`;

const WildButtonContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const UnoButtonContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, 0%);
`;

const PlayerContainer = styled.div`
  position: absolute;
  right: -8%;
  top: -10%;
  transform: translate(-50%, 0%);
`;

const Oval = styled.div`
  width: 90%;
  height: 90%;
  background: #107543;
  border-radius: 50%;
  border-style: solid;
  border-color: black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const GameCodeContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "Baloo Tamma 2", cursive;
  height: 2em;
  width: 5em;
  color: whitesmoke;
  font-size: 2em;
  background: #24ab68;
  border-radius: 0.2em;
  border-color: white;
  border-width: thin;
  padding: 0.1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
`;

const Code = styled.div`
  line-height: 1em;
  &:hover {
    color: grey;
    text-shadow: 0.1em 0.1em 0.1em black;
  }
`;

type BoardProps = {
  topCard: JSONCard | null;
  playersInfo: PlayerInfo[];
  position: number;
  gameCode: string | null;
  turnPosition: number | null;
  isHost: boolean;
  showPass: boolean;
  handleClickAddBotBtn: () => void;
  handleClickPlayerEmoji: () => void;
  handleClickStartGame: () => void;
  handleWildColorSelection: (color: Color) => void;
  handleCallUno: () => void;
  drawClickHandler: CardClickHandler;
  passClickHandler: CardClickHandler;
  suspendPlayerClickHandlers: boolean;
  wildPlayPending: boolean;
  showUnoButton: boolean;
  isNormalSide: boolean;
  handleLogError: (error: string) => void;
};

class Board extends Component<BoardProps> {
  constructor(props: BoardProps) {
    super(props);
    this.copyCodeToClip = this.copyCodeToClip.bind(this);
  }

  copyCodeToClip(): void {
    const { gameCode, handleLogError } = this.props;
    try {
      if (gameCode === null) {
        throw new Error("Unexpected copy when gameCode=null");
      }
      navigator.clipboard.writeText(gameCode);
    } catch (e) {
      handleLogError(`clipboard not accessible: ${e}`);
    }
  }

  render(): ReactElement {
    const {
      topCard,
      drawClickHandler,
      passClickHandler,
      playersInfo,
      position,
      gameCode,
      turnPosition,
      isHost,
      showPass,
      handleClickAddBotBtn,
      handleClickStartGame,
      handleWildColorSelection,
      handleCallUno,
      handleClickPlayerEmoji,
      suspendPlayerClickHandlers,
      wildPlayPending,
      showUnoButton,
      isNormalSide
    } = this.props;

    return (
      <BoardContainer>
        <Oval />
        {wildPlayPending && isNormalSide && (
          <WildButtonContainer>
            <Button
            variant="outline-light"
            style={{ boxShadow: "none" }}
            onClick={() => handleWildColorSelection(Color.BLUE)}>
              Blue
            </Button>
            <Button
            variant="outline-light"
            style={{ boxShadow: "none" }}
            onClick={() => handleWildColorSelection(Color.GREEN)}>
              Green
            </Button>
            <Button
            variant="outline-light"
            style={{ boxShadow: "none" }}
            onClick={() => handleWildColorSelection(Color.RED)}>
              Red
            </Button>
            <Button
            variant="outline-light"
            style={{ boxShadow: "none" }}
            onClick={() => handleWildColorSelection(Color.YELLOW)}>
              Yellow
            </Button>
          </WildButtonContainer>
        )}
        {wildPlayPending && !isNormalSide && (
          <WildButtonContainer>
            <Button
            variant="outline-light"
            style={{ boxShadow: "none" }}
            onClick={() => handleWildColorSelection(Color.PINK)}>
              Pink
            </Button>
            <Button
            variant="outline-light"
            style={{ boxShadow: "none" }}
            onClick={() => handleWildColorSelection(Color.TEAL)}>
              Teal
            </Button>
            <Button
            variant="outline-light"
            style={{ boxShadow: "none" }}
            onClick={() => handleWildColorSelection(Color.ORANGE)}>
              Orange
            </Button>
            <Button
            variant="outline-light"
            style={{ boxShadow: "none" }}
            onClick={() => handleWildColorSelection(Color.PURPLE)}>
              Purple
            </Button>
          </WildButtonContainer>
        )}
        {showUnoButton && (
          <UnoButtonContainer>
            <Button
              variant="outline-light"
              style={{ boxShadow: "none" }}
              onClick={handleCallUno}>
                Uno
              </Button>
          </UnoButtonContainer>
        )}
        <PlayerContainer>
          {playersInfo.map(function(player, index) {
            return (
              <Player
                info={player}
                rotation={Rotation.BOTTOM}
                hasAction={turnPosition !== null && turnPosition === index}
                handleClickAddBotBtn={handleClickAddBotBtn}
                handleClickPlayerEmoji={position === index ? handleClickPlayerEmoji : () => { return; }}
                suspendPlayerClickHandlers={suspendPlayerClickHandlers}
              />
            )
          })}
        </PlayerContainer>
        {/* <Player
          info={playersInfo[position]}
          rotation={Rotation.BOTTOM}
          hasAction={turnPosition !== null && turnPosition === position}
          handleClickAddBotBtn={handleClickAddBotBtn}
          handleClickPlayerEmoji={handleClickPlayerEmoji}
          suspendPlayerClickHandlers={suspendPlayerClickHandlers}
        />
        <Player
          info={playersInfo[(position + 1) % 4]}
          rotation={Rotation.LEFT}
          hasAction={
            turnPosition !== null && turnPosition === (position + 1) % 4
          }
          handleClickAddBotBtn={handleClickAddBotBtn}
          handleClickPlayerEmoji={() => {
            return;
          }}
          suspendPlayerClickHandlers={suspendPlayerClickHandlers}
        />
        <Player
          info={playersInfo[(position + 2) % 4]}
          rotation={Rotation.TOP}
          hasAction={
            turnPosition !== null && turnPosition === (position + 2) % 4
          }
          handleClickAddBotBtn={handleClickAddBotBtn}
          handleClickPlayerEmoji={() => {
            return;
          }}
          suspendPlayerClickHandlers={suspendPlayerClickHandlers}
        />
        <Player
          info={playersInfo[(position + 3) % 4]}
          rotation={Rotation.RIGHT}
          hasAction={
            turnPosition !== null && turnPosition === (position + 3) % 4
          }
          handleClickAddBotBtn={handleClickAddBotBtn}
          handleClickPlayerEmoji={() => {
            return;
          }}
          suspendPlayerClickHandlers={suspendPlayerClickHandlers}
        /> */}
        {gameCode === null && (
          <BoardCenter
            topCard={topCard}
            drawClickHandler={drawClickHandler}
            passClickHandler={passClickHandler}
            showPass={showPass} />
        )}
        {gameCode !== null && (
          <GameCodeContainer>
            <div style={{ fontSize: ".5em" }}>GAME CODE</div>
            <Code onClick={this.copyCodeToClip}>{gameCode}</Code>
            {isHost && playersInfo.length > 1 && (
              <Button
              variant="outline-light"
              style={{ boxShadow: "none", marginTop: "15px" }}
              onClick={handleClickStartGame}>
                Start Game
              </Button>
            )}
          </GameCodeContainer>
        )}
      </BoardContainer>
    );
  }
}

export default Board;
