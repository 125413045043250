export enum Color {
  BLUE = "Blue",
  GREEN = "Green",
  RED = "Red",
  YELLOW = "Yellow",
  WILD = "Wild",
  DRAW = "Draw",
  PINK = 'Pink',
  TEAL = 'Teal',
  ORANGE = 'Orange',
  PURPLE = 'Purple'
}
