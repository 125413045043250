import React, { Component, ReactElement } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

import Board from "./board";
import Card from "./card/card";
import { CardClickHandler, DummyClickHandler } from "../types/cardClickHandler";
import { JSONCard } from "../types/jsonCard";
import Hand from "./hand";
import { SocketEvent } from "../types/socketEvent";
import { Color } from "../types/color";
import { Rotation } from "../types/rotation";
import { PlayerInfo } from "../types/playerInfo";
import ButtonRack from "./buttonRack";
import LeaveGame from "./leaveGame";
import EmojiPicker from "./emojiPicker";
import { ServerGameState } from "../types/gameState";
import { getBaseURL } from "../connectionConfig";
import { Emoji } from "emoji-mart";
import { Socket } from "socket.io-client";
import ChatWindow, { ChatMessage } from "./chatWindow";

const GAME_HEIGHT = 500;
const GAME_WIDTH = 700;

const GameWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  background: #162447;
  display: inline-block;
  ${(props: { scale: number }) =>
    props.scale &&
    `
    transform: translate(-50%, -50%) scale(${props.scale})
  `};
`;

const ChatButtonWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 15px;
`

type GameProps = {
  position: number;
  gameCode: string;
  socket: SocketIOClient.Socket;
  handleLeaveGame: () => void;
};

type GameState = {
  scale: number;
  topCard: JSONCard | null;
  handJSONCards: JSONCard[];
  turnPosition: number | null;
  playersInfo: PlayerInfo[] | null;
  showGameCode: boolean;
  isHost: boolean;
  // client only
  showScoreboard: boolean;
  suspendScoreboardBtnClickHandler: boolean;
  suspendCardClickHandler: boolean;
  showLeaveGame: boolean;
  suspendLeaveBtnClickHandler: boolean;
  showPicker: boolean;
  wildPlayPending: boolean;
  cardDrawn: boolean;
  showUnoButton: boolean;
  isNormalSide: boolean;
  suspendPlayerClickHandlers: boolean;
  emojiSheet: string | null;
  showChatWindow: boolean;
  chatMessages: ChatMessage[];
};

class Game extends Component<GameProps, GameState> {
  static mod(n: number, m: number): number {
    return ((n % m) + m) % m;
  }

  constructor(props: GameProps) {
    super(props);
    this.state = {
      scale: 100,
      topCard: null,
      handJSONCards: [],
      turnPosition: null,
      playersInfo: null,
      showGameCode: true,
      isHost: false,
      showScoreboard: false,
      suspendScoreboardBtnClickHandler: false,
      suspendCardClickHandler: false,
      showLeaveGame: false,
      suspendLeaveBtnClickHandler: false,
      showPicker: false,
      wildPlayPending: false,
      cardDrawn: false,
      showUnoButton: false,
      isNormalSide: true,
      suspendPlayerClickHandlers: false,
      emojiSheet: null,
      showChatWindow: false,
      chatMessages: [],
    };
  }

  componentDidMount(): void {
    const { socket } = this.props;
    const baseURL = getBaseURL();

    fetch(baseURL + "/gameState")
      .then((res) => res.json())
      .then((serverGameState) => {
        this.setGameStateFromJSON(serverGameState);
        // if ("boardCards" in serverGameState) {
        //   this.setGameStateFromJSON(serverGameState);
        // }
      });

    if (
      typeof Emoji.defaultProps !== "undefined" &&
      typeof Emoji.defaultProps.backgroundImageFn !== "undefined"
    ) {
      const emojiSheetURL = Emoji.defaultProps.backgroundImageFn("apple", 64);
      fetch(emojiSheetURL)
        .then((res) => res.blob())
        .then((data) => {
          const emojiSheet = URL.createObjectURL(data);
          this.setState({ emojiSheet });
        });
    }

    if (window.innerHeight > window.innerWidth) {
      alert(
        " 🏞️ Uno is best enjoyed in landscape mode. 🏞️ \n 🔄 Rotate your phone for a nicer experience. 🔄 "
      );
    }

    socket.on(
      SocketEvent.SERVER_UPDATED_GAME_STATE,
      (serverGameState: ServerGameState | Record<string, never>) => {
        this.setGameStateFromJSON(serverGameState);
      }
    );

    socket.on(SocketEvent.SERVER_RELOAD_CONNECTION, () => {
      window.location.reload();
    });

    socket.on(SocketEvent.RECEIVE_CHAT_MESSAGE, (data: { username: string; message: string; }) => {
      const username = data.username;
      const message = data.message;
      this.setState({
        chatMessages: [...this.state.chatMessages, {username: username, message: message}],
        showChatWindow: true
      })
    });

    socket.on(SocketEvent.SERVER_ERROR, () => {
      alert("Oops! Something went wrong.");
    });

    this.resize();
    window.addEventListener("resize", this.resize.bind(this));
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  setGameStateFromJSON(
    serverGameState: ServerGameState | Record<string, never>
  ): void {
    this.setState({
      topCard: serverGameState.topCard,
      handJSONCards: serverGameState.handJSONCards,
      turnPosition: serverGameState.turnPosition,
      playersInfo: serverGameState.playersInfo,
      showGameCode: serverGameState.showGameCode,
      isHost: serverGameState.isHost,
      isNormalSide: serverGameState.isNormalSide
    });
    if (serverGameState.turnPosition !== this.props.position) {
      this.setState({ cardDrawn: false, showUnoButton: false })
    } else {
      const showUno = serverGameState.handJSONCards.length == 2 && this.hasPlayableCard(serverGameState.handJSONCards)
      this.setState({ showUnoButton: showUno })
    }
    // if ("boardCards" in serverGameState) {
    //   this.setState({
    //     boardCards: this.generateCards(
    //       serverGameState.boardCards,
    //       DummyClickHandler,
    //       false
    //     ),
    //     handJSONCards: serverGameState.handJSONCards,
    //     turnPosition: serverGameState.turnPosition,
    //     validSuits: serverGameState.validSuits,
    //     playersInfo: serverGameState.playersInfo,
    //     showGameCode: serverGameState.showGameCode,
    //     scorecardData: serverGameState.scorecardData,
    //   });
    // }
  }

  rotationBasedOnPosition(otherPos: number): Rotation {
    const { position } = this.props;
    const rotations = [
      Rotation.BOTTOM,
      Rotation.LEFT,
      Rotation.TOP,
      Rotation.RIGHT,
    ];
    const relativePos = Game.mod(otherPos - position, 4);

    return rotations[relativePos];
  }

  generateCards(
    jsonCards: JSONCard[],
    clickHandler: CardClickHandler,
    inHand: boolean
  ): ReactElement<Card>[] {
    const cards: ReactElement<Card>[] = [];
    let jsonCard: JSONCard;
    const amt = inHand ? this.state.handJSONCards.length : 4;
    for (let pos = 0; pos < amt; pos += 1) {
      jsonCard = jsonCards[pos];
      let card: ReactElement<Card>;
      if (jsonCard == null) {
        card = <></>;
      } else {
        card = (
          <Card
            color={jsonCard.color}
            value={jsonCard.value}
            cardId={jsonCard.cardId}
            wildColor={jsonCard.wildColor}
            handleCardClick={clickHandler}
            rotation={inHand ? undefined : this.rotationBasedOnPosition(pos)}
            invisible={false}
            playable={this.isCardPlayable(jsonCard)}
          />
        );
      }
      cards.push(card);
    }
    return cards;
  }

  hasPlayableCard(cards = this.state.handJSONCards): boolean {
    for (let i = 0; i < cards.length; i += 1) {
      if (this.isCardPlayable(cards[i])) {
        return true
      }
    }
    return false
  }

  isCardPlayable(jsonCard: JSONCard): boolean {
    if (this.state.turnPosition !== this.props.position) {
      return false;
    }
    const topCard = this.state.topCard;
    if (topCard === null) {
      return true
    }
    if (jsonCard.color === Color.WILD) {
      return true
    }
    if (topCard.color === Color.WILD && topCard.wildColor === jsonCard.color) {
      return true
    }
    if (jsonCard.color === topCard.color || jsonCard.value === topCard.value) {
      return true
    }
    return false
  }

  handleCardInHandClick(color: Color, value: string, cardId: number): void {
    let { turnPosition } = this.state;
    const { handJSONCards } = this.state;
    const { position, socket } = this.props;

    // not clientsturnPosition
    if (turnPosition !== position) {
      return;
    }

    const index = handJSONCards.findIndex(
      (card) =>
        card.color === color && card.value === value && card.cardId === cardId
    );
    const card = handJSONCards.splice(index, 1)[0];

    if (card.color === Color.WILD) {
      this.setState({ topCard: card, handJSONCards, wildPlayPending: true })
    } else {
      socket.emit(SocketEvent.CLIENT_PLAY_CARD, {
        card,
      });
      turnPosition = -1; // setting to non-valid position until game state update
      this.setState({ topCard: card, handJSONCards, turnPosition, wildPlayPending: false, cardDrawn: false });
    }
  }

  handleWildColorSelection(selectedColor: Color) {
    let { handJSONCards, topCard ,turnPosition, wildPlayPending } = this.state;
    const { socket } = this.props;

    if (!wildPlayPending) {
      // no wild pending
      return
    }
    if (topCard === null) {
      return
    }

    const card = topCard
    if (card.color === Color.WILD) {
      card.wildColor = selectedColor
    }
    socket.emit(SocketEvent.CLIENT_PLAY_CARD, {
      card,
    });
    turnPosition = -1; // setting to non-valid position until game state update
    this.setState({ topCard: card, handJSONCards, turnPosition, wildPlayPending: false });
  }

  handleCallUno() {
    const { socket } = this.props;
    socket.emit(SocketEvent.CLIENT_CALL_UNO, {});
    this.setState({ showUnoButton: false })
  }

  handleClickAddBotBtn(): void {
    const socket = this.props.socket;
    socket.emit(SocketEvent.CLIENT_ADD_BOT, {});
  }

  handleClickStartGameBtn(): void {
    const socket = this.props.socket;
    socket.emit(SocketEvent.CLIENT_START_GAME, {})
  }

  handleClickDrawCard(): void {
    let { turnPosition } = this.state;
    const { position, socket } = this.props;
    if (turnPosition !== position) {
      return;
    }
    this.setState({ cardDrawn: true, turnPosition: -1, wildPlayPending: false })
    socket.emit(SocketEvent.CLIENT_DRAW_CARD, {});
  }

  handleClickPass(): void {
    let { turnPosition, cardDrawn } = this.state;
    const { position, socket } = this.props;
    if (!cardDrawn || turnPosition !== position) {
      return;
    }
    this.setState({ cardDrawn: false, turnPosition: -1 })
    socket.emit(SocketEvent.CLIENT_PASS, {});
  }

  handleClickScoreboardBtn(): void {
    this.setState({
      showScoreboard: true,
      suspendScoreboardBtnClickHandler: true,
      suspendLeaveBtnClickHandler: true,
      suspendCardClickHandler: true,
      suspendPlayerClickHandlers: true,
    });
  }

  handleClickLeaveBtn(): void {
    this.setState({
      showLeaveGame: true,
      suspendScoreboardBtnClickHandler: true,
      suspendLeaveBtnClickHandler: true,
      suspendCardClickHandler: true,
      suspendPlayerClickHandlers: true,
    });
  }

  handleClickLeaveGameLeave(): void {
    this.props.socket.emit(SocketEvent.CLIENT_LEAVE_GAME, {});
    this.props.handleLeaveGame();
  }

  handleClickPlayerEmoji(): void {
    this.setState({
      showPicker: true,
      suspendScoreboardBtnClickHandler: true,
      suspendLeaveBtnClickHandler: true,
      suspendCardClickHandler: true,
      suspendPlayerClickHandlers: true,
    });
  }

  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  handleClickPickerEmoji(
    emoji: any,
    _: React.MouseEvent<Element, MouseEvent>
  ): void {
    /* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
    const { socket, position } = this.props;
    const { playersInfo } = this.state;

    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      playersInfo![position].emoji = emoji.native;
    } catch (e) {
      this.handleLogError(`could not set player emoji: ${e}`);
    }

    this.handleOutsideClick();

    socket.emit(SocketEvent.CLIENT_SELECT_EMOJI, { emoji: emoji.native });
  }

  handleOutsideClick(): void {
    this.setState({
      showScoreboard: false,
      showLeaveGame: false,
      showPicker: false,
    });
    // Prevent outside click from triggering click on scoreboardBtn or card
    setTimeout(() => {
      this.setState({
        suspendScoreboardBtnClickHandler: false,
        suspendCardClickHandler: false,
        suspendLeaveBtnClickHandler: false,
        suspendPlayerClickHandlers: false,
      });
    }, 50);
  }

  handleLogError(error: string): void {
    this.props.socket.emit(SocketEvent.CLIENT_LOG_ERROR, { error });
  }

  handleChatButtonClicked(): void {
    this.setState({
      showChatWindow: !this.state.showChatWindow,
    })
  }

  handleChatMessageSend(message: String): void {
    this.props.socket.emit(SocketEvent.SEND_CHAT_MESSAGE, {message: message});
  }

  resize(): void {
    const scale = Math.min(
      window.innerWidth / GAME_WIDTH,
      window.innerHeight / GAME_HEIGHT
    );
    this.setState({ scale });
  }

  render(): ReactElement {
    const {
      scale,
      topCard,
      playersInfo,
      showGameCode,
      handJSONCards,
      turnPosition,
      isHost,
      showScoreboard,
      suspendScoreboardBtnClickHandler,
      suspendCardClickHandler,
      showLeaveGame,
      suspendLeaveBtnClickHandler,
      showPicker,
      suspendPlayerClickHandlers,
      wildPlayPending,
      showUnoButton,
      isNormalSide,
      cardDrawn,
      emojiSheet,
      showChatWindow,
      chatMessages
    } = this.state;
    const { position, gameCode } = this.props;

    return (
      <>
        <ChatButtonWrapper>
        <Button
              variant="outline-light"
              style={{ boxShadow: "none" }}
              onClick={this.handleChatButtonClicked.bind(this)}
            >
              Chat
            </Button>
        </ChatButtonWrapper>
        {showChatWindow &&(<ChatWindow handleSendMessage={this.handleChatMessageSend.bind(this)} messages={chatMessages}/>)}
        <GameWrapper scale={scale}>
          {playersInfo !== null && (
            <>
              <Board
                topCard={topCard}
                playersInfo={playersInfo}
                position={position}
                gameCode={showGameCode ? gameCode : null}
                turnPosition={turnPosition}
                isHost={isHost}
                showPass={position === turnPosition && cardDrawn}
                handleClickAddBotBtn={this.handleClickAddBotBtn.bind(this)}
                handleClickPlayerEmoji={this.handleClickPlayerEmoji.bind(this)}
                handleClickStartGame={this.handleClickStartGameBtn.bind(this)}
                handleWildColorSelection={this.handleWildColorSelection.bind(this)}
                handleCallUno={this.handleCallUno.bind(this)}
                drawClickHandler={this.handleClickDrawCard.bind(this)}
                passClickHandler={this.handleClickPass.bind(this)}
                suspendPlayerClickHandlers={suspendPlayerClickHandlers}
                wildPlayPending={wildPlayPending}
                showUnoButton={showUnoButton}
                isNormalSide={isNormalSide}
                handleLogError={this.handleLogError.bind(this)}
              />
              <Hand
                cards={this.generateCards(
                  handJSONCards,
                  suspendCardClickHandler
                    ? DummyClickHandler
                    : this.handleCardInHandClick.bind(this),
                  true
                )}
              />
              <ButtonRack
                handleClickLeaveBtn={this.handleClickLeaveBtn.bind(this)}
                leaveBtnToggled={showLeaveGame}
                suspendLeaveBtnClickHandler={suspendLeaveBtnClickHandler}
                handleClickScoreboardBtn={this.handleClickScoreboardBtn.bind(
                  this
                )}
                scoreboardBtnToggled={showScoreboard}
                suspendScoreboardBtnClickHandler={
                  suspendScoreboardBtnClickHandler
                }
              />
              {this.state.emojiSheet !== null && (
                // This is very hacky, but it was the only way I managed to cache the emoji sheet...
                <img
                  src={this.state.emojiSheet}
                  style={{ height: "0", width: "0" }}
                  alt={""}
                ></img>
              )}
            </>
          )}
          {showLeaveGame && (
            <LeaveGame
              handleOutsideClick={this.handleOutsideClick.bind(this)}
              handleClickStay={this.handleOutsideClick.bind(this)}
              handleClickLeave={this.handleClickLeaveGameLeave.bind(this)}
            />
          )}
          {showPicker && (
            <EmojiPicker
              handleOutsideClick={this.handleOutsideClick.bind(this)}
              handleClickPickerEmoji={this.handleClickPickerEmoji.bind(this)}
              emojiSheet={emojiSheet}
            />
          )}
        </GameWrapper>
      </>
    );
  }
}

export default Game;
